.not-found-page__container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.not-found-page__info {
  font-size: 24px;
  color: #666666;
  text-align: center;
}

.not-found-page__info img {
  width: 300px;
}

@media (max-width: 767px) {
  .not-found-page__info img {
    width: 200px;
  }
}
