.breadcrumb-skeleton__container {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
}

.breadcrum-skeleton__arrow {
  padding-left: 7px;
  padding-right: 7px;
  color: #999999;
}

@media (max-width: 950px) {
  .breadcrumb-skeleton__container {
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media (max-width: 480px) {
  .breadcrumb-skeleton__container {
    font-size: 12px;
  }
}
