body {
  background-color: #eeeeee;
}

.common-layout__content {
  max-width: 950px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}
